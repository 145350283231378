import React from "react";

export interface InputProps {
    autoComplete?: string;
    className?: string;
    id?: string;
    name?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    placeholder?: string;
    type?: "text" | "number" | "tel" | "password" | "email";
    value?: string;
}

// TODO hasError prop for red borders

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type = "text", ...inputProps }, ref) => {
        return (
            <input
                type={type}
                className={`w-full border-gray-300 border rounded-sm p-2 ${className}`}
                ref={ref}
                data-testid="input"
                {...inputProps}
            />
        );
    }
);
