import React, { forwardRef } from "react";

interface Option {
    value?: string | number;
    label: string | number;
}

export interface SelectProps {
    autoComplete?: string;
    className?: string;
    id?: string;
    name?: string;
    onBlur?: React.FocusEventHandler<HTMLSelectElement>;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    options: Option[];
    value?: string;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(({ options, className, ...selectProps }, ref) => {
    return (
        <select ref={ref} {...selectProps} className={`w-full border-gray-300 border rounded-sm p-3  ${className}`}>
            {options.map((opt) => (
                <option key={opt.label + "" + opt.value} value={opt.value}>
                    {opt.label}
                </option>
            ))}
        </select>
    );
});
