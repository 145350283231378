import React, { useRef, useState } from "react";

export interface TextareaProps {
    autoComplete?: string;
    className?: string;
    id?: string;
    name?: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    placeholder?: string;
    value?: string;
    maxRows?: number;
    minRows?: number;
}

// TODO hasError prop for red borders

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, onChange, maxRows = 15, minRows = 5, ...textareaProps }, ref) => {
        const internalRef = useRef<HTMLTextAreaElement>(null);
        const textareaRef = ref || internalRef;

        const [rows, setRows] = useState(minRows);

        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const textareaLineHeight = 30;

            const previousRows = e.target.rows;
            e.target.rows = minRows; // reset number of rows in textarea

            const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

            if (currentRows === previousRows) {
                e.target.rows = currentRows;
            }

            if (currentRows >= maxRows) {
                e.target.rows = maxRows;
                e.target.scrollTop = e.target.scrollHeight;
            }

            setRows(currentRows < maxRows ? currentRows : maxRows);

            if (onChange) {
                onChange(e);
            }
        };

        return (
            <>
                <textarea
                    className={`w-full border-gray-300 border rounded-sm p-1 text-xl ${className}`}
                    ref={textareaRef}
                    rows={rows}
                    data-testid="input"
                    onChange={handleChange}
                    {...textareaProps}
                />
            </>
        );
    }
);
