import React from "react";

export interface FormFieldProps {
    label: string;
    htmlFor: string;
    errorLabel?: string;
    helpLabel?: string;
    className?: string;
}

export const FormField: React.FC<FormFieldProps> = ({
    children,
    label,
    errorLabel,
    helpLabel,
    htmlFor,
    className = "",
}) => {
    return (
        <fieldset className={`${className}`}>
            {errorLabel && <span className="text-red-600">{errorLabel}</span>}
            {children}
            {helpLabel && <span>{helpLabel}</span>}
        </fieldset>
    );
};
