import React, { useState, forwardRef } from "react";
import { nanoid } from "nanoid";
import { SelectProps, Select } from "../Select";
import { FormField, FormFieldProps } from "./FormField";

export interface FormSelectProps extends SelectProps, Omit<FormFieldProps, "htmlFor"> {}

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
    ({ label, id, errorLabel, helpLabel, className, ...inputProps }, ref) => {
        const [uid] = useState(id || nanoid(8));

        return (
            <FormField label={label} htmlFor={uid} errorLabel={errorLabel} helpLabel={helpLabel} className={className}>
                <Select id={uid} ref={ref} {...inputProps} />
            </FormField>
        );
    }
);
