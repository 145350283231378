import React, {useContext, useState} from "react";

import {ReactComponent as OrklaLogo} from "../graphics/logo/oga24_logo_v2.svg"
import {User} from "../types";
import {AuthContext, httpPost} from "../data";
import {useForm} from "react-hook-form";
import {Button} from "../components";
import {Form} from "../components";
import useMediaQuery from "../hooks/useMediaQuery";

interface LoginFormData {
    username: string;
    password: string;
}

interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}


const popupVariants = {
    opacity: {
        from: 0,
        to: 1
    },
    duration: 2000,
    delay: 200
};

// @ts-ignore
export default () => {

    const {login} = useContext(AuthContext);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)
    const isiPhone = useMediaQuery("(max-width: 450px)")
    const isMobile = useMediaQuery("(max-width: 768px)")

    const [errorMessage, setErrorMessage] = useState("");
    const {register: loginRegister, handleSubmit: handleLoginSubmit, errors} = useForm<LoginFormData>();


    const handleLogin = (formData: LoginFormData) => {
        setErrorMessage("");
        setIsLoggingIn(true)
        httpPost<AuthResponse>("auth/login", JSON.stringify({
            password: formData.password.toLowerCase(),
            email: 'orkla@direkte.stream'
        }))
            .then((data) => {
                login(data.accessToken, data.refreshToken, data.user);
                setIsLoggingIn(false)
            })
            .catch((e) => {
                if (e.body && e.body.Message) {
                    setErrorMessage(e.body.Message.replace("UnauthorizedError: ", "").replace("User does not exist or wrong password", "Wrong code"));
                }
                setIsLoggingIn(false)
            });
    };

    return (
        <main className="min-h-screen w-screen overflow-hidden flex mobile:flex-col">
            <div
                className="player-bg desktop:w-3/5 mobile:h-96 text-center text-white align-middle ">
                <div className="w-full  overflow-hidden h-full flex justify-center flex-shrink-0 flex-grow " style={{
                    translate: isMobile ? "0" : "0"
                }}>


                        <OrklaLogo style={{
                            width: isMobile ? "40%" : "50%",
                            //maxHeight: isMobile ? "200%":"",
                            //translate: isMobile ? (isiPhone ? "28% 10%": "25% 10%") : "20% 5%",
                            //transform: isMobile ? "scale(130%)" : "scale(80%)"
                        }}

                        />

                </div>
            </div>
            <div
                className="bg-orklapurple desktop:ml-auto desktop:w-2/5 text-orklapurple text-center py-12 mobile:mt-0 center-container mobile:min-h-screen  ">
                <div className="center-holder w-full mobile:mt-10 mobile:bg-orklapurple">
                    <h1 className="text-6xl smallDesktop:text-3xl orkla-font-heavy text-white ">Join the Celebration!</h1>


                    <form onSubmit={handleLoginSubmit(handleLogin)}
                          className="flex flex-col mobile:w-9/12 smallDesktop:w-3/5  largeDesktop:w-3/5 extraLargeDesktop:w-2/5 mx-auto space-y-4 pt-32 text-black p-4 ">
                        {/*<Form.FormSelect
                            options={[
                                {label: 'Orkla ASA', value: 'orkla@direkte.stream'},
                                {label: 'Orkla Foods', value: 'orkla@direkte.stream'},
                                {label: 'Orkla Confectionery & Snacks', value: 'orkla@direkte.stream'},
                                {label: 'Orkla Care', value: 'orkla@direkte.stream'},
                                {label: 'Orkla Food Ingredients', value: 'orkla@direkte.stream'},
                                {label: 'Orkla Consumer & Financial Investments', value: 'orkla@direkte.stream'},
                                {label: 'Jotun & Key Business Partners', value: 'orkla@direkte.stream'},
                            ]}
                            label="Company name"
                            name="username"
                            autoComplete="username"
                            ref={loginRegister({required: "Field is required"})}
                        />*/}
                        <h2 className="orkla-font-normal text-white">Please enter your password to join the celebration!</h2>
                        {errors.username && (
                            <span role="alert" className="text-white">
                                {errors.username.message}
                            </span>
                        )}

                        <Form.FormInput
                            label="Enter password"
                            placeholder="Enter password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            ref={loginRegister({required: "Field is required"})}
                        />
                        {errors.password && (
                            <span role="alert" className="text-white">
                                {errors.password.message}
                            </span>
                        )}

                        {errorMessage && (
                            <div
                                className="bg-orkladeepred bold border border-gray-600 text-white text-xl p-3 mobile:text-lg my-2">{errorMessage}</div>
                        )}


                        <div className="w-full pb-12">
                            <Button
                                className={(!isLoggingIn ? "bg-gray-200" : "bg-gray-400") + " w-full  rounded p-2 my-2 hover:bg-gray-400 inline-flex justify-center"}>
                                {isLoggingIn ? <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white my-auto"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#000"
                                                stroke-width="4"></circle>
                                        <path className="opacity-75" fill="#000"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span className="my-auto">Joining</span></> : "Join"
                                }
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
};


