import React, {useContext, useState} from 'react';
import './tailwind.output.css';
import './App.css';
import LoginView from "./views/LoginView";
import PlayerView from "./views/PlayerView";
import {SWRConfig} from 'swr'
import {AuthContext} from "./data";


function App() {

    const { isLoggedin } = useContext(AuthContext);

    return (
        <SWRConfig value={{
            refreshInterval: 20000,
        }}>
            <div className="">
                {!(isLoggedin) ? <LoginView /> : <PlayerView/>}
            </div>
        </SWRConfig>
    );
}

export default App;
