import React from "react";

export interface ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    color?: "green" | "blue";
    className?: string;
    isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    onClick,
    className = "",
    color = "blue",
    isLoading = false,
}) => {
    return (
        <button
            onClick={onClick}
            className={` font-bold duration-100 transition-colors block ${className}`}
        >
            {isLoading ? "Loading..." : children}
        </button>
    );
};
