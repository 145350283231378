import React, {useContext, useEffect, useRef} from "react";
import {AuthContext, httpGet, httpPost} from "../data";
import useSWR from "swr/esm/use-swr";
import {Event} from "../types"
// @ts-ignore
import ReactJWPlayer from "react-jw-player";

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import {Button} from "../components/Button";
import Cookies from 'universal-cookie';
import useCookie from "../data/cookies";


const cookies = new Cookies();


const popupVariants = {
    opacity: {
        from: 0,
        to: 1
    },
    duration: 2000
};

interface Props {
    event: Event
    accessToken: string | undefined
    slug: string
}

interface PollOption {
    optionId: string
    optionLabel: string
    optionWeight: number
    visible: boolean
}

interface Poll {
    pollId: string;
    eventId: string;
    open: boolean;
    visible: boolean;
    options: [PollOption];
    created: number;
    updated: number;
}


//import "./App.scss";
/*
require("@silvermine/videojs-chromecast")(videojs);*/

require("@silvermine/videojs-airplay")(videojs);

export function VideoPlayer({src, posterSrc}: { src: string, posterSrc: string }) {
    const videoRef = useRef(null);
    const playerRef = useRef(null);


    useEffect(() => {
        if (videoRef.current && videoRef.current !== null) {
            const video = videoRef.current;

            // @ts-ignore
            playerRef.current = videojs(video, {
                autoplay: false,
                controls: true,
                preload: "auto",
                //playbackRates: [0.5, 1, 1.5, 2, 2.5],
                userActions: {
                    hotkeys: true
                },
                controlBar: {
                    pictureInPictureToggle: true
                },
                poster:
                posterSrc,
                sources: [{src}],
                techOrder: ["chromecast", "html5"]
            });
            /*
                        playerRef.current.chromecast(); */
                        // @ts-ignore
            //playerRef.current.airPlay();

            /*playerRef.current.vttThumbnails({
                src:
                    "https://image.mux.com/XAUyVQNQUpHCFH2qXYgi3JOcmGs4xovhczrhHQsgqJ4/storyboard.vtt"
            });*/

            // @ts-ignore
            playerRef.current.on("play", () => {
                // @ts-ignore
                playerRef.current.bigPlayButton.hide();
            });

            // @ts-ignore
            playerRef.current.on("pause", () => {
                // @ts-ignore
                playerRef.current.bigPlayButton.show();
            });
        }

        return () => {
            if (playerRef.current) {
                // @ts-ignore
                playerRef.current.dispose();
            }
        };
    }, [videoRef]);

    return <video ref={videoRef} className="video video-js"/>;
}


const Poll = ({poll}: { poll: Poll }) => {

    const [getCookie, setCookie] = useCookie('pollVoteCasted:' + poll.pollId)

    const voteHandler = (poll: Poll, pollOption: PollOption) => {
        httpPost(
            "events/polls/vote/" + poll.pollId,
            JSON.stringify({
                "pollOptionId": pollOption.optionId
            })
        ).then(() =>
            setCookie(pollOption.optionId)
        )
    }

    if (getCookie) {
        // vote has been cast
        return (
            <div className="flex justify-center mobile:flex-col w-full">
                {poll.options.map((pollOption) => {
                    if (getCookie === pollOption.optionId) {
                        return (
                            <Button
                                className="btn  m-4 mx-10 py-2 px-4 bg-gray-900 text-white btn-selected btn-deactivated">
                                {pollOption.optionLabel}
                            </Button>
                        )
                    }
                    return (
                        <Button className="btn m-4 mx-10 py-2 px-4 bg-gray-900 text-white   btn-deactivated">
                            {pollOption.optionLabel}
                        </Button>
                    )
                })}
            </div>
        )
    } else {
        // vote has NOT been cast yet
        return (
            <div className="flex justify-center mobile:flex-col w-full">
                {poll.options.map((pollOption) => {
                    return (
                        <Button onClick={() => {
                            voteHandler(poll, pollOption)
                        }} className="btn btn-reactive text-white m-4 mx-10 py-2 px-4 ">
                            {pollOption.optionLabel}
                        </Button>
                    )
                })}
            </div>
        )
    }


}

export const VideoAndChatView = ({event, accessToken, slug}: Props) => {

    return (
        <div className="min-h-screen player-bg w-screen">
            <main className={'flex flex-col h-full '}>
                {event?.text !== "" && <div className="text-center text-white orkla-font-heavy m-6 text-xl ">
                    {event?.text}
                </div>}


                {event.userHasAccess && (
                    <div
                        className=" rounded mobile:w-full player-holder mx-auto mt-4 h-full text-white orkla-font-normal">
                        {event.displayVideo && event.hlsUrl &&

                            <>
                         {/*       <VideoPlayer
                                    src={event.hlsUrl}
                                    posterSrc={event.posterUrl}
                                />*/}
                                                     <ReactJWPlayer
                            playerId="jwplayer"

                            customProps={{repeat: true, cast: {}}}
                            playerScript="https://cdn.jwplayer.com/libraries/69PLM1uc.js"
                            file={event.hlsUrl}
                            image={event.posterUrl}
                                />
                            </>
                        }
                        {!event.displayVideo &&
                            <div
                                className="rounded mobile:w-full player-holder mx-auto mt-4 h-full text-white orkla-font-normal relative poster-bg  ">
                                <div className=" aspect-ratio-169 absolute top-0 left-0 w-full poster-bg-transparent">
                                </div>
                                <div className="aspect-ratio-169  shadow-lg rounded ">
                                    <div
                                        className="mx-auto w-full text-center text-white text-2xl mobile:text-xl absolute center-holder">
                                        <div className="whitespace-pre-line">{event.title.replace("\\n", "\n")}</div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                )}
            </main>


        </div>
    )
}


export default () => {

    const slug = "orkla"

    const {isLoggedin, user, accessToken} = useContext(AuthContext);
    const {data: event, mutate, revalidate} = useSWR<Event>(
        isLoggedin ? "events/watch/" + slug : "events/slug/" + slug,
        httpGet
    );


    if (!event) {
        return (
            <div className="center-container w-full h-screen player-bg">
                <div className="center-holder">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white my-auto"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#000"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="#000"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
        );
    }

    return (<VideoAndChatView event={event} accessToken={accessToken} slug={slug}/>)


}
