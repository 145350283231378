import React, { useState, forwardRef } from "react";
import { nanoid } from "nanoid";
import { TextareaProps, Textarea } from "../Textarea";
import { FormField, FormFieldProps } from "./FormField";

export interface FormTextareaProps extends TextareaProps, Omit<FormFieldProps, "htmlFor"> {}

export const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
    ({ label, id, errorLabel, helpLabel, className, ...textareaProps }, ref) => {
        const [uid] = useState(id || nanoid(8));

        return (
            <FormField label={label} htmlFor={uid} errorLabel={errorLabel} helpLabel={helpLabel} className={className}>
                <Textarea id={uid} ref={ref} {...textareaProps} />
            </FormField>
        );
    }
);
