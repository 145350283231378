import React, { useState, forwardRef } from "react";
import { nanoid } from "nanoid";
import { InputProps, Input } from "../Input";
import { FormField, FormFieldProps } from "./FormField";

export interface FormInputProps extends InputProps, Omit<FormFieldProps, "htmlFor"> {}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
    ({ label, id, errorLabel, helpLabel, className, ...inputProps }, ref) => {
        const [uid] = useState(id || nanoid(8));

        return (
            <FormField label={label} htmlFor={uid} errorLabel={errorLabel} helpLabel={helpLabel} className={className}>
                <Input id={uid} ref={ref} {...inputProps} />
            </FormField>
        );
    }
);
